.folio-item-container {
	padding: 1rem;
	display: flex;
	flex-direction: column;
}

.folio-items {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.folio-item-portion {
	padding: 1rem;
}

.widget-container {
	width: 100%;
	flex-grow: 1;

	* {
		width: 100%;
		height: 100%;
	}
}

.folio-item-img {
	width: 100%;
	height: auto;
}

