
.folio-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	text-align: center;
	padding: 1rem;
}

.folio-header {
	padding: 1rem;
}

.folio-item-container {
	margin-top: 3rem;
}
