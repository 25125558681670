.App {
	height: 100%;
}


.compensate-topbar {
	margin-top: 4rem;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: calc(100vh - 3rem);
}

.copyright {
	text-align: center;
	padding: 1rem;
}

.center-text {
	text-align: center;
}
