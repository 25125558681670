.topbar-nav {
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
}

.nav-item {
	padding: 1rem;
}
